<template>
	<div id="myList" class="myList">
		<ul>
			<li :class="{ 'active': isActive, 'on': item.origin==1 }" @click="goDetail(item.id)" v-for="(item, index) in goodList" :key="index">
				<img :src="item.image" class="image" />
				<p class="name">
					<span class="dot wanshui_dot">{{ item.trade_name }}</span>
					{{ item.store_name }}
				</p>
<!-- 				<div class="money font-color-red">
					￥
					<span class="num">{{ item.price }}</span>
				</div> -->
				<div class="vip-money">
					<div class="vip" v-if="item.vip_price && item.vip_price > 0">
						￥{{ item.vip_price || 0 }}
						<img src="@assets/images/vip.png" class="image" />
					</div>
					<span class="num">已售{{ item.sales }}{{ item.unit_name }}</span>
				</div>
			</li>
			<div class="noCart" v-if="goodList.length === 0"><img src="@assets/images/noGood.png" /></div>
		</ul>
	</div>
</template>
<script>
export default {
	name: 'GoodList',
	props: {
		goodList: {
			type: Array,
			default: () => []
		},
		isActive: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {};
	},
	methods: {
		// 商品详情跳转
		goDetail(id) {
			const { href } = this.$router.resolve({
				path: '/detail/' + id,
				query: { loading: true }
			});
			window.open(href, '_blank');
		}
	}
};
</script>
<style lang="less" scoped>
.myList ul {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	.noCart {
		width: 100%;
		text-align: center;
		padding: 50px 0;
		img {
			max-width: 100%;
		}
	}
	li.active {
		width: 100%;
		margin: 1% 0 5% 0;
	}
	li.on {
		border-top: 3px solid #FC5B5D;
	}
	li {
		list-style: none;
		width: 19%;
		padding: 10px 0;
		background-color: white;
		-webkit-transition: all 0.2s linear;
		transition: all 0.2s linear;
		margin: 0.5%;
		cursor: pointer;
		img {
			display: block;
			width: 160px;
			height: 160px;
			margin: 0 auto;
		}
		p.name {
			margin: 16px 10px;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-box-orient: vertical;
			font-family: PingFang SC;
			line-height: 26px;
			.dot {
				margin: 0;
			}
		}
		.money {
			margin: 0 10px;
			color: #f60;
			font-size: 12px;
			.num {
				font-size: 18px;
			}
		}
		.vip-money {
			display: flex;
			margin: 10px 10px 0;
			justify-content: space-between;
			align-items: center;
			font-size: 12px;
			color: #282828;
			.vip {
				display: flex;
				align-items: center;
				img {
					width: 23px;
					height: 10px;
					margin-left: 6px;
				}
			}
			span.num {
				color: #aaa;
			}
		}
	}
	li:hover {
		z-index: 2;
		-webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
		box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
		-webkit-transform: translate3d(0, -2px, 0);
		transform: translate3d(0, -2px, 0);
	}
}
.myList ul li p .del {
	margin-left: 0.5em;
	color: #b0b0b0;
	text-decoration: line-through;
}
.myList #more {
	text-align: center;
	line-height: 280px;
}
.myList #more a {
	font-size: 18px;
	color: #333;
}
.myList #more a:hover {
	color: #ff6700;
}
.myList ul li .delete {
	position: absolute;
	top: 10px;
	right: 10px;
	display: none;
}
.myList ul li:hover .delete {
	display: block;
}
.myList ul li .delete:hover {
	color: #ff6700;
}
</style>
