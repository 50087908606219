<template>
	<div class="goodsCon" v-loading="loading">
		<div class="type-page">
			<div class="goods-img">
				<div class="bigimg" ref="bigimg" @mouseover="isShow = false" @mouseout="isShow = true" @mousemove="scaleThisArea">
					<div class="magnifybox" ref="magnifybox" :hidden="isShow"></div>
					<img :src="storeInfo.slider_image[current]" />
				</div>
				<div class="pic-list">
					<i class="el-icon-arrow-left" @click="prev"></i>
					<div class="imgli">
						<img :class="current === index ? 'on' : ''" v-for="(item, index) in storeInfo.slider_image" :key="index" :src="item" @click="toggleImg(index)" />
					</div>
					<i class="el-icon-arrow-right" @click="next"></i>
				</div>
				<div class="magnify" ref="magnify" :hidden="isShow"></div>
				<span>商品编码： {{ storeInfo.goods_code }}</span>
			</div>
			<div class="goods-info">
				<div class="country-box">
					<div>{{ storeInfo.origin_country + ' | ' + storeInfo.brand_name }}</div>
				</div>
				<div class="introduce">
					<div class="dot wanshui_dot">{{ storeInfo.trade_name }}</div>
					<span>{{ storeInfo.store_name }}</span>
				</div>
				<div class="info-view">
					<div class="item">
						<lable>价格：</lable>
						<div class="price">
							￥
							<span class="num">{{ productSelect.price }}</span>
							<div class="vip" v-if="storeInfo.vip_price && storeInfo.vip_price > 0">
								￥{{ storeInfo.vip_price || 0 }}
								<img src="@assets/images/vip.png" class="image" />
							</div>
						</div>
					</div>
					<div class="item">
						<lable>国家：</lable>
						<div>{{ storeInfo.origin_country }}</div>
					</div>
					<div class="item">
						<lable>品牌：</lable>
						<div>{{ storeInfo.brand_name }}</div>
					</div>
					<div class="item">
						<lable>仓库：</lable>
						<div>{{ storeInfo.origin == 1 ? '1号仓': '2号仓'}}</div>
					</div>
				</div>
				<div class="choose-item" v-for="(item, indexw) in productAttr" :key="indexw">
					<div class="title">{{ item.attr_name }}</div>
					<el-radio-group fill="#f60" size="mini" v-model="item.index" @change="tapAttr(indexw, $event)">
						<el-radio-button v-for="(itemn, indexn) in item.attr_value" :key="indexn" :label="itemn.attr"></el-radio-button>
					</el-radio-group>
				</div>
				<div class="choose-item">
					<div class="title">数量</div>
					<el-input-number v-model="CartNum" @change="CartNumDes" :min="1" :max="productSelect.stock" size="small"></el-input-number>
					<span class="tip">(库存：{{ productSelect.stock }}{{ storeInfo.unit_name }})</span>
					<span class="tip">
						已售
						<span>{{ productSelect.sales }}{{ storeInfo.unit_name }}</span>
					</span>
				</div>
				<div class="choose-btn">
					<button :disabled="productSelect.stock === 0 ? true : false" :class="productSelect.stock === 0 ? 'info' : 'plain'" @click="goCat(1)">{{ productSelect.stock === 0 ? '已售罄' : '立即购买' }}</button>
					<button @click="goCat(0)" hidden>加入购物车</button>
				</div>
			</div>
		</div>
		<div class="type-page">
			<div class="hot-product">
				<div class="title">优品推荐</div>
				<Good-list :good-list="goodList" :isActive="isActive"></Good-list>
			</div>
			<div class="image-detail">
				<el-tabs type="border-card">
					<el-tab-pane label="产品介绍">
						<div class="conter" v-html="storeInfo.description"></div>
						<div class="kj_conter" v-if="storeInfo.tradType === '跨境保税' || storeInfo.tradType === '海外直邮'">
							<img src="@assets/images/goods_xfgz.jpg" alt="" />
						</div>
					</el-tab-pane>
					<el-tab-pane label="用户评价">用户评价</el-tab-pane>
				</el-tabs>
			</div>
		</div>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import { getProductDetail, postCartAdd } from '@api/user';
import GoodList from '@components/GoodList';
export default {
	name: 'GoodsCon',
	components: {
		GoodList
	},
	data() {
		return {
			isActive: true,
			id: 0,
			storeInfo: {
				slider_image: []
			},
			middleware: {},
			productAttr: [],
			productValue: [],
			goodList: [],
			CartNum: 1,
			destination: '',
			loading: false,
			// 图片放大
			current: 0,
			isShow: true,
			productSelect: {}
		};
	},
	computed: {},
	watch: {
		$route() {
			let that = this;
			that.id = that.$route.params.id;
			that.productCon();
		}
	},
	mounted: function() {
		let that = this;
		that.id = that.$route.params.id;
		that.productCon();
		if (that.$route.query.loading) {
			that.loading = true;
		}
	},
	methods: {
		...mapActions(['aGetCartNum']),
		next() {
			let that = this;
			if (that.current == that.storeInfo.slider_image.length - 1) {
				that.current = 0;
			} else {
				that.current++;
			}
		},
		prev() {
			let that = this;
			if (that.current == 0) {
				that.current = that.storeInfo.slider_image.length - 1;
			} else {
				that.current--;
			}
		},
		toggleImg(index) {
			let that = this;
			that.current = index;
		},
		scaleThisArea(event) {
			let that = this;
			var bigimg = that.$refs.bigimg;
			var magnify = that.$refs.magnify;
			var magnifybox = that.$refs.magnifybox;
			var x = event.clientX - 660 - magnifybox.offsetWidth / 2;
			var y = event.clientY - 184 - magnifybox.offsetHeight / 2;
			//考虑边界问题
			if (x < 0) {
				x = 0;
			} else if (x > bigimg.offsetWidth - magnifybox.offsetWidth) {
				x = bigimg.offsetWidth - magnifybox.offsetWidth;
			}
			if (y < 0) {
				y = 0;
			} else if (y > bigimg.offsetHeight - magnifybox.offsetHeight) {
				y = bigimg.offsetHeight - magnifybox.offsetHeight;
			}
			magnifybox.style.left = x + 'px';
			magnifybox.style.top = y + 'px';
			//修改大图背景的位置
			magnify.style.backgroundImage = 'url(' + that.storeInfo.slider_image[that.current] + ')';
			magnify.style.backgroundRepeat = 'no-repeat';
			var bgx = (-x * 400) / 200;
			var bgy = (-y * 400) / 200;
			magnify.style.backgroundPosition = bgx + 'px ' + bgy + 'px';
		},
		//产品详情接口；
		productCon: function() {
			let that = this;
			getProductDetail(that.id)
				.then(res => {
					that.loading = false;
					that.storeInfo = res.data.storeInfo;
					that.goodList = res.data.good_list;
					that.productAttr = res.data.productAttr; //所有的规格
					// that.productValue = res.data.productValue; //所有的规格
					for (let i in res.data.productValue) {
						that.productValue.push(res.data.productValue[i]);
					}
					//所有的规格的排列组合(对象转为数组)
					that.DefaultSelect();
				})
				.catch(err => {
					that.$dialog.error(err.msg);
					that.$router.go(-1);
				});
		},
		DefaultSelect: function() {
			let that = this;
			let value = [];
			for (let i = 0; i < that.productValue.length; i++) {
				if (that.productValue[i].stock > 0) {
					value = that.productAttr.length ? that.productValue[i].suk.split(',') : [];
					break;
				}
			}
			for (let i = 0; i < that.productAttr.length; i++) {
				that.$set(that.productAttr[i], 'index', value[i]);
			}
			that.productValue.forEach(item => {
				if (item.suk === value.join(',')) {
					that.$set(that.productSelect, 'price', item.price);
					that.$set(that.productSelect, 'stock', item.stock);
					that.$set(that.productSelect, 'sales', item.sales);
					that.$set(that.productSelect, 'unique', item.unique);
					return;
				}
			});
		},
		//  选择数量
		CartNumDes(value) {
			this.CartNum = value;
		},
		// 选择规格
		tapAttr(indexw, e) {
			let that = this;
			that.productAttr[indexw].index = e;
			let value = that
				.getCheckedValue()
				.sort()
				.join(',');
			for (let i = 0; i < that.productValue.length; i++) {
				if (value == that.productValue[i].suk) {
					that.productSelect.price = that.productValue[i].price;
					that.productSelect.stock = that.productValue[i].stock;
					that.productSelect.sales = that.productValue[i].sales;
					that.productSelect.unique = that.productValue[i].unique;
					return;
				} else {
					that.productSelect.stock = 0;
				}
			}
		},
		// 获取被选中属性；
		getCheckedValue: function() {
			let productAttr = this.productAttr;
			let value = [];
			for (let i = 0; i < productAttr.length; i++) {
				for (let j = 0; j < productAttr[i].attr_value.length; j++) {
					if (productAttr[i].index === productAttr[i].attr_value[j].attr) {
						value.push(productAttr[i].attr_value[j].attr);
					}
				}
			}
			return value;
		},
		// news=1 立即代购  news=0 加入进货单
		goCat: function(news) {
			let that = this;
			let q = {
				productId: that.id,
				cartNum: that.CartNum,
				new: news,
				uniqueId: that.productSelect.unique
			};
			postCartAdd(q)
				.then(function(res) {
					if (news) {
						// 立即代购
						that.$router.push({ path: '/order/submit/' + res.data.cartId, query: { trade_name: that.storeInfo.trade_name } });
					} else {
						that.$dialog.toast({
							mes: '添加购物车成功',
							callback: () => {
								that.$store.dispatch('aGetCartNum'); // 更新购物车数量
							}
						});
					}
				})
				.catch(res => {
					return that.$dialog.toast({ mes: res.msg });
				});
		}
	}
};
</script>

<style lang="less" scoped>
.goodsCon {
	background: #fff;
	.type-page {
		width: 1232px;
		padding-top: 30px;
		margin: 0 auto;
		display: flex;
		.hot-product {
			width: 275px;
			height: max-content;
			padding: 0 10px;
			margin-right: 15px;
			border: 1px solid #f6f6f6;
			background: #f4f4f4;
			.title {
				background: #e4e7ed;
				color: #909399;
				text-align: center;
				height: 38px;
				line-height: 36px;
				margin: 0 -10px;
			}
		}
		.image-detail {
			width: calc(100% - 300px);
			.goods-parameter {
				display: none;
				margin-bottom: 20px;
				.title {
					display: flex;
					border-bottom: 1px solid #409eff;
					align-items: center;
					.chinese {
						background-color: #409eff;
						color: #fff;
						border-radius: 20px 0;
						padding: 3px 20px;
					}
					.english {
						padding-left: 10px;
						color: #409eff;
						font-size: 14px;
					}
				}
				.parameter-content {
					.import-attribute {
						.classify {
							margin: 26px 0 10px 0;
							font-size: 14px;
							color: #666;
							line-height: 22px;
						}
						.clearfix {
							padding: 0;
							list-style: none;
							display: flex;
							color: #999;
							font-size: 12px;
							line-height: 24px;
							li {
								width: 24%;
								margin-right: 1%;
								margin-right: 20px;
							}
						}
					}
				}
			}
			.conter {
				width: 100%;
				/deep/ img {
					width: 100%;
				}
			}
			.kj_conter {
				width: 100%;
				img {
					max-width: 100%;
				}
			}
		}
		.goods-img {
			position: relative;
			width: 400px;
			margin-right: 24px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.bigimg {
				width: 400px;
				height: 400px;
				position: relative;
				left: 0;
				.magnifybox {
					position: absolute;
					top: 0;
					left: 0;
					width: 100px;
					height: 100px;
					background-color: #fede4f;
					opacity: 0.6;
					cursor: move;
				}
				img {
					width: 100%;
					border: 1px solid #f6f6f6;
					height: 400px;
				}
			}
			.pic-list {
				display: flex;
				width: 100%;
				margin: 10px 0;
				i {
					width: 24px;
					height: 64px;
					border: 1px solid #f6f6f6;
					box-sizing: border-box;
					line-height: 64px;
					text-align: center;
					font-size: 18px;
					cursor: pointer;
					color: #999;
				}
				.imgli {
					flex: 1;
					overflow: hidden;
					margin-left: 8px;
					height: 65px;
					img {
						width: 63px;
						height: 63px;
						border: 1px solid #f6f6f6;
						margin: 0 10px;
						cursor: pointer;
					}
					img.on {
						border: 1px solid #f60;
					}
				}
			}
			.magnify {
				position: absolute;
				z-index: 99;
				top: 0;
				left: 401px;
				width: 400px;
				height: 400px;
				border: 1px solid #f6f6f6;
				background: #fff;
			}
			span {
				font-size: 14px;
			}
		}
		.goods-info {
			flex: 1;
			color: #666;
			font-size: 12px;
			.country-box {
				display: flex;
				align-items: center;
				font-size: 14px;
				img {
					width: 40px;
					margin-right: 10px;
				}
			}
			.introduce {
				margin: 10px 0;
				font-size: 20px;
				font-weight: 700;
				color: #333;
				display: flex;
				align-items: center;
				.dot {
					margin-left: 0;
				}
			}
			.info-view {
				padding: 16px 24px 4px;
				background: rgba(255, 102, 0, 0.04);
				margin: 10px 0 20px 0;
				.item {
					display: flex;
					align-items: center;
					margin-bottom: 16px;
					lable {
						width: 52px;
						color: #999;
					}
					.price {
						display: flex;
						font-size: 12px;
						line-height: 20px;
						font-weight: 700;
						color: #f60;
						.num {
							font-size: 18px;
						}
						.vip {
							display: flex;
							align-items: center;
							margin-left: 20px;
							color: #282828;
							img {
								width: 23px;
								height: 10px;
								margin-left: 4px;
							}
						}
					}
				}
			}
			.choose-item {
				display: flex;
				align-items: center;
				margin-left: 24px;
				margin-top: 10px;
				.tip {
					margin-left: 20px;
					span {
						color: #f60;
					}
				}
				.title {
					width: 52px;
					color: #999;
				}
				.el-radio {
					.el-radio__input {
						display: none;
					}
				}
				// .on {
				// 	border: #f60 1px solid;
				// 	color: #f60;
				// 	padding: 4px 12px;
				// }
			}
			.choose-btn {
				display: flex;
				margin: 30px 0 0;
				button {
					border-radius: 2px;
					color: #ffffff;
					width: 200px;
					text-align: center;
					margin-right: 20px;
					background: transparent;
					height: 32px;
					line-height: 32px;
					cursor: pointer;
				}
				button.plain {
					border: 1px solid #f60;
					background: #f60;
				}
				button.info {
					background: #bbb;
				}
			}
		}
	}
}

.el-carousel .el-carousel__indicator .el-carousel__button {
	background-color: rgba(163, 163, 163, 0.8);
}
</style>
